import { graphql, Link, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Tilt from "react-parallax-tilt"
import Fade from "react-reveal/Fade"
import { Link as ScrollLink } from "react-scroll"
import awardLogo from "../../assets/images/msp-awards/mspawardlogo.png"
import NominateFormSucess from "../../assets/images/msp-awards/nominate_formSucess.png"
import CustomAccordion from "../../components/common/accordian"
import Buttons from "../../components/common/button"
import CarouselComponent from "../../components/common/carousel-component"
import FormBuilder from "../../components/common/FormBuilder/Form-Builder"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/mspawards-2022.scss"

const Awards2022 = () => {
  const [award, setAward] = useState(0)
  const [submitSuccess, setSubmitSuccess] = useState(true)

  const nominateFormdata = [
    {
      name: "firstname",
      type: "text",
      label: "Your name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Your email",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "website",
      validateEmpty: "true",
      type: "text",
      label: "Your MSP's website",
      errorMessage: "Please enter website URL",
    },
    {
      name: "award_category",
      type: "select",
      choices: [
        "MSP website of the year",
        "The customer experience champion",
        "MSP with the best work culture",
        "MSP with the best social media presence",
        "Best MSP Community contributor",
        "MSP with the most innovative sales/marketing campaign",
        "Emerging MSP of the year",
        "Vertical MSP of the year",
        "Solo MSP of the year",
      ],
      label: "Category",
      errorMessage: "Please enter category",
    },
    {
      name: "message",
      validateEmpty: "true",
      type: "textarea",
      label: "Tell us briefly on why you nominated your MSP",
      errorMessage: "Please enter a valid message",
    },
  ]
  let categorylength

  const nextBtn = d => {
    if (award < categorylength - 1) {
      setAward(award + 1)
    } else setAward(0)
  }

  const previousBtn = () => {
    if (award > 0) {
      setAward(award - 1)
    } else setAward(award)
  }

  const handleAfterSubmit = () => {
    setSubmitSuccess(true)
  }

  const responsiveSpeaker = {
    mobile2: {
      breakpoint: { max: 535, min: 480 },
      items: 1.5,
    },
    mobile3: {
      breakpoint: { max: 480, min: 460 },
      items: 1.4,
    },
    mobile5: {
      breakpoint: { max: 460, min: 435 },
      items: 1.35,
    },
    mobile4: {
      breakpoint: { max: 435, min: 0 },
      items: 1.2,
    },
  }

  const responsive = {
    desktopLarge: {
      breakpoint: { max: 4000, min: 1200 },
      items: 1,
    },
    desktopSmall: {
      breakpoint: { max: 1200, min: 1024 },
      items: 1,
    },

    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 578, min: 535 },
      items: 1,
    },

    mobile2: {
      breakpoint: { max: 535, min: 480 },
      items: 1,
    },
    mobile3: {
      breakpoint: { max: 480, min: 460 },
      items: 1,
    },
    mobile5: {
      breakpoint: { max: 460, min: 435 },
      items: 1,
    },
    mobile4: {
      breakpoint: { max: 435, min: 0 },
      items: 1,
    },
  }

  const InfoBox = props => {
    const { info, heading } = props
    return info.map((el, idx) => {
      return (
        <>
          <Col
            lg={3}
            className={`d-dsk ${idx == 2 ? "" : "info-border-box prelative"}`}
          >
            <div className="info-box">
              <div className="p14">
                <p className="fw-bolder">{el}</p>
              </div>
              <div className="p16">
                <p>{heading[idx]}</p>
              </div>
            </div>
          </Col>
        </>
      )
    })
  }

  const ReasonCards = props => {
    const { title, subtext, icon } = props
    return (
      <>
        <Col lg={4} className="reasons-card-wrap ">
          <img src={icon} alt="log" />
          <p className="heading fw-bold">{title}</p>
          <p className="txt-gold description">{subtext}</p>
        </Col>
      </>
    )
  }

  const CategoryCard = props => {
    return (
      <Fade bottom distance={"25px"} duration={1000}>
        <div className="categoryHeading-wrap prelative">
          <h2 className="categoryHeading mx-auto fw-bold">{props.data}</h2>
        </div>
      </Fade>
    )
  }

  const SpeakerCards = props => {
    const { title, subtext, image } = props
    return (
      <>
        <Col lg={4} xs={12}>
          <Tilt
            className="parallax-effect-glare-scale"
            perspective={800}
            scale={1.02}
          >
            <div className="speaker-card">
              <div className="speaker-image">
                <LazyLoad throttle={200} height={200}>
                  <img src={image?.url} alt="speaker" />
                </LazyLoad>
              </div>

              <p className="title fw-bold">{title}</p>

              <p className="subtext">{subtext}</p>
            </div>
          </Tilt>
        </Col>
      </>
    )
  }

  return (
    <div>
      <StaticQuery
        query={graphql`
          query AwardsOne {
            SuperOps {
              pageComponents(where: { pageName: "MSP AWARDS" }) {
                hero {
                  tag
                  tagLink
                  heroExcerptList
                  animAltText
                }
                seo {
                  keywords
                  description
                  title
                  image {
                    id
                    url
                  }
                }
                sectionName
                pageContents {
                  heading
                  subtext {
                    html
                  }
                  detailIcon {
                    url
                  }
                  detailContent
                  detailHeading
                }
                timeline {
                  time
                  agenda
                  title
                  speakerName
                  speakerTitle
                  speaker {
                    url
                  }
                }
                links {
                  linkName
                  icon {
                    url
                  }
                  authorName
                }
                faqs {
                  category
                  question
                  answerrt {
                    html
                  }
                }
                card {
                  cardName
                  image {
                    url
                  }
                  title
                  subtext
                }
                images {
                  url
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pageComponents.map(page => {
              const {
                hero,
                seo,
                pageContents,
                timeline,
                links,
                faqs,
                card,
                sectionName,
                images,
              } = page
              const reasonCard = [pageContents[0]]
              const category = [pageContents[1]]
              const purpose = [pageContents[2]]
              categorylength = category[0].detailHeading.length
              return (
                <div className="mspAwards-2022 bg-dark">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                    twitterOG
                  >
                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        tabs={[
                          { name: "categories", id: "0" },
                          { name: "why participate", id: "1" },
                          { name: "jury", id: "3" },
                          { name: "faq", id: "4" },
                        ]}
                        transparentScroll
                      />
                    </header>
                    <div className="msp-container">
                      <section className="Hero position-relative">
                        <div className="gradient"></div>
                        <Container>
                          <div className="wrapper position-relative">
                            <img src={awardLogo} className="hero-img" alt="" />
                            <h1 className="hero-text fw-bold"> {hero.tag}</h1>
                            <div className="d-flex p24 justify-content-center calendar-wrap">
                              <SVGIcon name="mspawards/calendar" />
                              <p className="event-date">{hero.tagLink}</p>
                            </div>
                          </div>
                        </Container>
                      </section>
                      <div className="main-wrapper">
                        <section className="info-section">
                          <Container>
                            <center>
                              <Row className="justify-content-around info-wrap">
                                <InfoBox
                                  info={hero.heroExcerptList}
                                  heading={hero.animAltText}
                                />

                                <div
                                  style={{
                                    borderBottom: "1.5px solid #EBA86C",
                                  }}
                                  className={`d-mob d-flex p-0`}
                                >
                                  <div className="info-box w-50 mobile-info-border prelative">
                                    <div className="p14">
                                      <p className="fw-bolder">
                                        {hero.heroExcerptList[0]}
                                      </p>
                                    </div>
                                    <div className="p16">
                                      <p>{hero.animAltText[0]}</p>
                                    </div>
                                  </div>
                                  <div className="info-box w-50">
                                    <div className="p14">
                                      <p className="fw-bolder">
                                        {hero.heroExcerptList[1]}
                                      </p>
                                    </div>
                                    <div className="p16">
                                      <p>{hero.animAltText[1]}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="info-box d-mob">
                                  <div className="p14">
                                    <p className="fw-bolder">
                                      {hero.heroExcerptList[2]}
                                    </p>
                                  </div>
                                  <div className="p16">
                                    <p>{hero.animAltText[2]}</p>
                                  </div>
                                </div>
                              </Row>
                              <ScrollLink to="nominate_form_id">
                                <Buttons
                                  theme="primary gold register-button"
                                  text="NOMINATE NOW"
                                />
                              </ScrollLink>
                            </center>
                          </Container>
                        </section>

                        <section className="reasons ptb25">
                          <Container className="Layout-container">
                            <div className="reasons-wrapper mx-auto">
                              <div className="d-flex prelative">
                                <h4 className="reasons-heading fw-bold">
                                  Why are we doing this?
                                </h4>
                                <span className="underlineYellow"></span>
                              </div>
                              <div className="d-dsk">
                                <Row className="">
                                  {reasonCard[0].detailHeading.map(
                                    (el, idx) => {
                                      return (
                                        <ReasonCards
                                          icon={
                                            reasonCard[0].detailIcon[idx].url
                                          }
                                          title={el}
                                          subtext={
                                            reasonCard[0].detailContent[idx]
                                          }
                                        />
                                      )
                                    }
                                  )}
                                </Row>
                              </div>
                            </div>
                          </Container>

                          <Container className="d-mob caros mt50">
                            <Row>
                              <CarouselComponent
                                status
                                swipeable
                                displayArrow
                                responsive={responsiveSpeaker}
                                autoPlay={false}
                              >
                                {reasonCard[0].detailHeading.map((el, idx) => {
                                  return (
                                    <ReasonCards
                                      icon={reasonCard[0].detailIcon[idx].url}
                                      title={el}
                                      subtext={reasonCard[0].detailContent[idx]}
                                    />
                                  )
                                })}
                              </CarouselComponent>
                            </Row>
                          </Container>
                          <div className="reason-subtitle-wrap mx-auto">
                            <p className="reason-desc">
                              And that’s what we’re changing with the SuperOps
                              MSP awards 2022.
                            </p>
                          </div>
                          <div id="0"></div>
                        </section>

                        <section className="category ptb25">
                          <Container className="Layout-container">
                            <div className="category-wrapper mx-auto">
                              <div className="d-flex prelative">
                                <h4 className="category-heading fw-bold">
                                  Categories
                                </h4>
                                <span className="underlineYellow"></span>
                              </div>
                              <div className="awardscaro mx-auto  mt100">
                                <Row className="caro-card  mx-auto prelative">
                                  <CategoryCard
                                    data={category[0].detailHeading[award]}
                                  />
                                  <div className="caro-btn-wrap d-flex justify-content-between position-absolute w-100">
                                    <button
                                      className="carousel-btn left prelative"
                                      onClick={previousBtn}
                                    >
                                      <SVGIcon name="mspawards/arrow" />
                                    </button>
                                    <span className="slide-pagination">
                                      {award + 1}/
                                      {category[0].detailHeading.length}
                                    </span>
                                    <button
                                      className="carousel-btn right prelative"
                                      onClick={nextBtn}
                                    >
                                      <SVGIcon name="mspawards/arrow" />
                                    </button>
                                  </div>
                                  <img
                                    src={awardLogo}
                                    className="categ-img"
                                    alt="AwardLogo"
                                  />
                                </Row>
                              </div>
                            </div>
                            <div className="categoryButton d-flex justify-content-center">
                              <ScrollLink to="nominate_form_id">
                                <Buttons
                                  theme="primary gold register-button"
                                  text="NOMINATE NOW"
                                />
                              </ScrollLink>
                            </div>
                          </Container>
                          <div id="1"></div>
                        </section>

                        <section className="purpose ptb25">
                          <Container className="Layout-container mt48">
                            <Row className="justify-content-center">
                              <Col lg={9}>
                                <div className="d-flex heading-wrapper altv3">
                                  <h1 className="heading fw-bold">
                                    {purpose[0].heading}
                                  </h1>

                                  <div className="line"></div>
                                </div>
                                {purpose[0].detailHeading.map((el, index) => {
                                  return (
                                    <>
                                      <div className="d-flex content-purpose">
                                        <div
                                          className="p16"
                                          style={{ flex: "2" }}
                                        >
                                          <p className="sub-heading fw-bold">
                                            {el}
                                          </p>
                                        </div>
                                        <div
                                          className="p16"
                                          style={{ flex: "7" }}
                                        >
                                          <p className="content">
                                            {purpose[0].detailContent[index]}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })}
                              </Col>
                            </Row>
                          </Container>
                          <div id="2"></div>
                        </section>

                        <section className="speakers ptb25">
                          <Container>
                            <div className="speakers-wrapper mx-auto">
                              <div className="d-flex heading-wrapper altv3">
                                <h1 className="speakers-heading heading fw-bold">
                                  {card.cardName}
                                </h1>
                                <div className="line"></div>
                              </div>

                              <div className="d-dsk">
                                <Row>
                                  {card.title.map((el, idx) => {
                                    return (
                                      <SpeakerCards
                                        title={el}
                                        image={card.image[idx]}
                                        subtext={card.subtext[idx]}
                                      />
                                    )
                                  })}
                                </Row>
                              </div>
                            </div>
                          </Container>

                          <Container className="d-mob caros">
                            <Row>
                              <CarouselComponent
                                status
                                swipeable
                                responsive={responsiveSpeaker}
                                autoPlay={false}
                              >
                                {card.title.map((el, idx) => {
                                  return (
                                    <SpeakerCards
                                      title={el}
                                      image={card.image[idx]}
                                      subtext={card.subtext[idx]}
                                    />
                                  )
                                })}
                              </CarouselComponent>
                            </Row>
                          </Container>
                          <div id="nominate_form_id"></div>
                        </section>

                        <section className="nominate-form ptb25">
                          <Container className="Layout-container">
                            <div className="nominate-wrapper mx-auto">
                              <div className="d-flex heading-wrapper altv3">
                                <h1 className="speakers-heading heading fw-bold">
                                  Nominate
                                </h1>
                                <div className="line"></div>
                              </div>
                              <Row className="justify-content-center">
                                <Col lg={7}>
                                  <div className="form-wrapper d-flex align-items-center">
                                    {submitSuccess ? (
                                      <>
                                        <div className="form-closed mx-auto">
                                          <p className="message">
                                            Registrations for the SuperOps MSP
                                            awards has now closed. We will be
                                            announcing the winners on Dec 20th.
                                            In the meantime, please consider
                                            following us on{" "}
                                            <a
                                              href="https://www.linkedin.com/company/superops-ai/mycompany/"
                                              target="_blank"
                                              className="link"
                                            >
                                              LinkedIn
                                            </a>
                                          </p>
                                        </div>
                                        <div className="success-box dspnone">
                                          <center>
                                            <img
                                              src={NominateFormSucess}
                                              alt="nominate-logo"
                                              width="138px"
                                              className="nominate-logo"
                                            />
                                            <p className="first">
                                              Woohoo! You’re successfully
                                              registered!
                                            </p>
                                            <p className="second">
                                              We’ll let you know if you’ve won!
                                            </p>
                                            <Buttons
                                              onClick={() =>
                                                setSubmitSuccess(false)
                                              }
                                              theme={
                                                "primary gold register-button"
                                              }
                                              text={"NOMINATE AGAIN"}
                                            />
                                          </center>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <FormBuilder
                                          formClassName="forms"
                                          errorClassName="error-message"
                                          inputErrorClassName="error-active"
                                          data={nominateFormdata}
                                          buttonText="NOMINATE NOW"
                                          buttonClassName="primary gold register-button"
                                          IpStackData
                                          endpoint={
                                            process.env
                                              .HUBSPOT_MSP_AWARDS_ENDPOINT
                                          }
                                          url={
                                            process.env.HUBSPOT_MSP_AWARDS_URL
                                          }
                                          formName="Nominate Form - Superops"
                                          afterSubmit={handleAfterSubmit}
                                        />
                                        <div className="p12 form-footer">
                                          <p>
                                            We care about protecting your data.
                                            Here’s our{" "}
                                            <Link to="/privacy">
                                              Privacy policy
                                            </Link>
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Container>
                          <div id="4"></div>
                        </section>

                        <section className="faq-section ptb25">
                          <Container>
                            <div className="faq">
                              <div className="outer mx-auto">
                                <h2 className="faq-heading text-left">
                                  We're sure you have questions
                                </h2>
                                <CustomAccordion items={faqs} />
                              </div>
                            </div>
                          </Container>
                        </section>
                      </div>
                    </div>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default Awards2022
